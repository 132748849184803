var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('validation-observer',{ref:"simple"},[_c('b-form',[_c('b-card-code',{attrs:{"title":"إضافة مكافأة"}},[_c('validation-observer',{ref:"refFormObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-row',[_c('b-col',{attrs:{"md":"3","xl":"3"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-for":"title","state":errors.length > 0 ? false : null}},[_c('label',[_vm._v(" العنوان")]),_c('b-form-input',{attrs:{"type":"text","id":"input-title"},model:{value:(_vm.title),callback:function ($$v) {_vm.title=$$v},expression:"title"}})],1),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1),_c('b-col',{attrs:{"md":"3","xl":"3"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-for":"rewardType","state":errors.length > 0 ? false : null}},[_c('label',[_vm._v(" نوع المكافأة")]),_c('v-select',{attrs:{"options":_vm.optionerewardType,"reduce":function (val) { return val.value; }},model:{value:(_vm.rewardType),callback:function ($$v) {_vm.rewardType=$$v},expression:"rewardType"}})],1),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1),(_vm.rewardType==0)?_c('b-col',{attrs:{"md":"3","xl":"3"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-for":"amount","state":errors.length > 0 ? false : null}},[_c('label',[_vm._v(" الكمية")]),_c('b-form-input',{attrs:{"type":"number","id":"input-default"},model:{value:(_vm.amount),callback:function ($$v) {_vm.amount=$$v},expression:"amount"}})],1),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1):_vm._e(),_c('b-col',{attrs:{"md":"1","xl":"3"}},[_c('b-button',{attrs:{"variant":"purple"},on:{"click":_vm.updateReward}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"PlusIcon"}}),_c('span',{staticClass:"align-middle"},[_vm._v(" تعديل المكافأة")])],1)],1)],1)]}}])})],1)],1)],1),_c('b-col',{attrs:{"md":"4","xl":"2"}},[_c('b-button',{attrs:{"variant":"purple "},on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('span',{staticClass:"align-middle"},[_vm._v("رجوع")])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }